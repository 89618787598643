import {memo} from "react";

//w-[calc(100%-720px)] mx-[5%] px-[7%] mx-[50px] px-[200px]
//2% promena
const ContainerWrapper = ({backgroundImage,children}) => {
    return <div className="mx-[5%] lg:mx-[10%] xxl:mx-[5%] xsm:px-[0%] xxl:px-[7%] flex flex-col items-center justify-center"
        style={backgroundImage ? backgroundImage : null}
    >
    {children}
    </div>
}

export default memo(ContainerWrapper)