'use client'
import { useState } from 'react';
import styles from "@/app/styles/components/pages/home/home-banner.module.scss";
import "@/app/styles/global.scss";
import {useRouter} from "next/navigation";
import useWindowSize from "@/app/hooks/useWindowSize";
import HomeBannerMobile from "@/app/components/pages/home/HomeBanerMobile";

const HomeBanner = ({ data }) => {
    const [hovered, setHovered] = useState(false);
    const router = useRouter();
    const {isMobile} = useWindowSize()

    const startImageStyle = {
        backgroundImage: `url('${data.homePage?.first_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width: '100%',
        // height: '1000px',
    };

    const endImageStyle = {
        backgroundImage: `url('${data.homePage?.home_banner_second_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        position: 'absolute',
        width: '100%',
        height: '100%',
        transition: 'transform 1.9s ease-out, opacity 1.9s ease-out',
        transform: hovered ? 'scale(1.1)' : 'none',
        opacity: hovered ? 1 : 0,
    };

    return ( !isMobile ?
            <div className={'mt-[-65px] lg:h-[700px] xxl:h-[800px] 2xl:h-[900px]'} style={startImageStyle}>
                <div className={'hidden md:flex flex-row justify-center items-center lg:pt-[100px] xl:pt-[60px] 2xl:pt-[140px] 3xl:pt-[100px]'}>
                    <div className={'flex flex-col text-center items-center xl:ml-[60px] 2xl:ml-[70px] 3xl:ml-[200px] pt-[100px]'}>
                        <img className={'mb-[12px] lg:w-[350px] xl:w-[380px] xxl:w-[440px] 3xl:w-[450px] 3xl:w-[500px]'}  src={data?.homePage?.animation_crux_image}/>
                        <div className={'text-[#8F7E76] lg:text-[24px] xl:text-[26px] xxl:text-[30px] 2xl:text-[36px] font-[300] leading-[51.5px] ml-[15px] lg:tracking-[14px] xl:tracking-[18px] 2xl:tracking-[16px] 3xl:tracking-[20px] mb-[20px]'}>{data?.homePage?.animation_ashwagandha}</div>
                        <div className={'text-[#8F7E76] lg:text-[14px] xxl:text-[16px] font-[700] leading-[24px] tracking-[4.8px] 2xl:tracking-[5px] 3xl:tracking-[6px] mb-[30px]'}>{data?.homePage?.animation_text}</div>
                        <button onClick={() => router.push('/prodavnica')} className={'bg-cruxGreen text-white text-[16px] font-[800] 2xl:font-[800] tracking-[1.6px] max-w-[290px] rounded-[30px] px-[30px] py-[10px] 2xl:px-[60px] 2xl:py-[16px]'}>{data?.homePage?.animation_button_text}</button>
                    </div>
                    <div>
                        <img className={'lg:w-[340px] xl:w-[400px] xxl:w-[500px] 2xl:w-[500px] 3xl:w-[600px]'} src={data?.homePage?.animation_bottle}/>
                    </div>
                </div>
                <div className={'flex-col md:hidden flex-col justify-center items-center pt-[75px]'}>
                    <div className={'flex flex-col text-center items-center'}>
                        <img className={'mb-[15px]'} width={220} height={40} src={data?.collagen?.collagen_crux_logo_image}/>
                        <h1 className={'text-[#8F7E76] text-[14px] font-[700] leading-[16px] tracking-[4.2px] mb-[40px]'}>{data?.collagen?.collagen_banner_title}</h1>
                        <img src={data?.collagen?.collagen_box_image}/>
                    </div>
                    <div>
                    </div>
                </div>
                

                {/*//TODO: ovo je animacija! za sada cemo je izbaciti*/}
                {/*<div className="xl:pt-[0px] 2xl:pt-[100px] group flex flex-col justify-center" style={{*/}
                {/*    backgroundImage: `url('${data.homePage?.animation_bg_image}')`,*/}
                {/*    backgroundSize: "cover",*/}
                {/*    backgroundPosition: "top",*/}
                {/*    width: "100%",*/}
                {/*}}>*/}
                {/*    <div className={"flex justify-center"}>*/}
                {/*        <div className="items-center my-auto flex flex-col justify-start">*/}
                {/*            <p className="relative top-[-200px] right-[-60%] group-hover:top-[200px] xl:group-hover:right-[-80px] 2xl:group-hover:right-0 duration-700  text-[20px] text-cruxGray tracking-[5px]">{data?.homePage?.animation_text}</p>*/}
                {/*            <img width={500} src={data.homePage?.animation_crux_image} className="scale-[2.5] translate-x-[60%] group-hover:translate-x-0 group-hover:scale-100 duration-700  z-10"/>*/}
                {/*            <p className="text-[42px] text-cruxGray tracking-[10px] transition-opacity duration-700 opacity-0 group-hover:opacity-100 group-hover:mt-[20px]">{data?.homePage?.animation_ashwagandha}</p>*/}
                {/*            <button onClick={() => router.push('/prodavnica')} className="z-50 cursor-pointer transition-opacity duration-700 opacity-0 group-hover:opacity-100 px-[70px] py-[16px] rounded-[30px] bg-cruxGreen text-white tracking-[1.6px] mt-[60px]">*/}
                {/*                {data?.homePage?.animation_button_text}*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*        <div className="-translate-x-[40%] group-hover:-translate-x-0 duration-700 z-20">*/}
                {/*            <img width={600} height={600} src={data?.homePage?.animation_bottle} className="z-20"/>*/}
                {/*        </div>*/}
                {/*        <div className={'absolute xl:left-[-50px] xl:bottom-[-300px] 2xl:left-0 2xl:bottom-0 group-hover:mb-[-5%] duration-700'}>*/}
                {/*            <img src={data.homePage?.animation_planina_levo}/>*/}
                {/*        </div>*/}
                {/*        <div className={'absolute xl:mb-[-25%] 2xl:mb-[-10%] left-0 bottom-0 xl:group-hover:mb-[-35%] 2xl:group-hover:mb-[-20%] group-hover:scale-[1.2] duration-700'}>*/}
                {/*            <img src={data.homePage?.animation_brdo}/>*/}
                {/*        </div>*/}
                {/*        <div className={'absolute xl:right-[-100px] 2xl:right-0 xl:bottom-[-300px] 2xl:bottom-0 group-hover:mb-[-15%] duration-700'}>*/}
                {/*            <img src={data.homePage?.animation_planina_desno}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="xl:-mt-[30%] 2xl:-mt-[35%] z-30 group-hover:-mt-[20%] duration-700" style={{*/}
                {/*        backgroundImage: `url('${data.homePage?.animation_front_flowers}')`,*/}
                {/*        backgroundRepeat: "no-repeat",*/}
                {/*        backgroundPositionX: "center",*/}
                {/*        backgroundPositionY: "bottom",*/}
                {/*        backgroundSize: "contain",*/}
                {/*    }}>*/}
                {/*        <img className={'opacity-100'} src={data.homePage?.animation_front_fog} />*/}
                {/*    </div>*/}
                {/*</div>*/}

        {/*// <div className={styles.bannerImage} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>*/}
        {/*//     <div className="relative h-[1158px] w-full">*/}
        {/*//         <div style={startImageStyle}></div>*/}
        {/*//         <div style={endImageStyle}></div>*/}
        {/*//     </div>*/}
        {/*// </div>*/}
            </div>
            :
            <HomeBannerMobile data={data}/>
    );
};

export default HomeBanner;
