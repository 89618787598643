import {memo} from "react";
//mx-[5%]
const MainContainerWrapper = ({backgroundImage,children}) => {
    return <div className={`mx-[5%] flex flex-col items-center justify-center`}
                style={backgroundImage ? backgroundImage : null}
    >
        {children}
    </div>
}

export default memo(MainContainerWrapper)