import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeAcceptChallenge.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeBanner.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeBaseInfo.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeClinicalStudy.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeDayMedicine.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeSupport.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeTestimonials.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeTryNow.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeWhatIsIn.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/home/HomeWin.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/not-found.js");
