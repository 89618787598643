'use client'
import React, {useRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";
import SwipeLeftIcon from "@/app/components/icons/SwipeLeftIcon";
import SwipeRightIcon from "@/app/components/icons/SwipeRightIcon";
import "@/app/styles/global.scss";
import useWindowSize from "@/app/hooks/useWindowSize";
import {useRouter} from "next/navigation";
import SwipeLeftIconMobile from "@/app/components/icons/SwipeLeftIconMobile";
import SwipeRightIconMobile from "@/app/components/icons/SwipeRightIconMobile";

const HomeTestimonials  = ({data}) => {

    const {isMobile} = useWindowSize();
    const router = useRouter();

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-next-arrow z-10`}
                style={{ ...style, display: "block", right: "15%" }}
                onClick={onClick}>
                <SwipeRightIcon />
            </div>
        );
    };

    const NextArrowMobile = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-next-arrow z-10`}
                style={{ ...style, display: "block", right: "3%", top:"42%"  }}
                onClick={onClick}>
                <SwipeRightIconMobile />
            </div>
        );
    };

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-prev-arrow z-10`}
                style={{ ...style, display: "block", left: "15%" }}
                onClick={onClick}>
                <SwipeLeftIcon />
            </div>
        );
    };

    const PrevArrowMobile = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-prev-arrow z-10`}
                style={{ ...style, display: "block", left: "3%", top:"42%" }}
                onClick={onClick}>
                <SwipeLeftIconMobile />
            </div>
        );
    };

    const [currentSlide, setCurrentSlide]=useState(0);
    const testimonialsRef = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 5,
        slidesToScroll: 1,
        centerMode: true,
        beforeChange: (_, newIndex) => setCurrentSlide(newIndex),
        focusOnSelect: true,
        centerPadding: isMobile ? '10px' : '0px',
        nextArrow: isMobile ? <NextArrowMobile/> : <NextArrow />,
        prevArrow: isMobile ? <PrevArrowMobile/> : <PrevArrow />,
    };

    return <div className='flex flex-col my-[100px]'>
        <MainContainerWrapper>
        <div className='flex flex-col text-center w-full mx-auto'>
            <div className='xsm:text-[10px] text-[#626261] leading-[24px] font-[300] md:text-[14px]  xsm:tracking-[5px] md:tracking-[8.4px] md:mb-[20px]'>{data.homePage.testamonials_title}</div>
            <div className={'xsm:text-[24px] mt-[14px] leading-[24px] tracking-[4.8px] text-cruxGray lg:text-[30px] xxl:text-[36px] md:tracking-[7.2px] md:leading-[45px] font-[300]'} >{data.homePage.testamonials_subtitle1}</div>
            <h1 className={'xsm:text-[32px] mt-[7px] leading-[24px] tracking-[3.2px] text-cruxGray lg:text-[40px] xxl:text-[48px] md:leading-[45px] md:tracking-[4.8px] font-[900]'}>{data.homePage.testamonials_subtitle2}</h1>
            <span className={'border-b mt-[14px] border-cruxBrown w-[150px] lg:w-[600px] mx-auto'}></span>
        </div>
            <div className='flex flex-row mx-auto w-full xsm:py-0 lg:py-12 '>
                <Slider {...settings} className='w-full pt-[20px]'>
                    {data.homePage.image_repeater?.map((item, index) => (
                        <div key={index} className={`rounded-3xl py-[60px] transition-transform duration-1000 ease-in-out ${index === currentSlide ? 'scale-125 relative z-[20]' : 'scale-90'} ${index !== currentSlide ? 'filter blur-sm' : ''}`}>
                            <div style={{
                                backgroundImage: `url(${item.image.url})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                // width: isMobile && '70vw',
                                height: '30vh',
                                margin: '0 auto',
                            }}>
                            </div>
                            <p className='text-[14px] mt-[20px] leading-[42px] font-thin italic text-center'>{data.homePage.testamonials_message}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        <div className='mx-auto my-[20px]'>
            <button onClick={() => router.push('/utisci?filter=ashwagandha')} className='bg-cruxGreen rounded-[30px] font-[800] tracking-[1.2px] md:tracking-[1.6px] text-white px-[40px] md:px-[70px] py-[10px] md:py-[16px]'>{data.homePage.testamonials_button}</button>
        </div>
        </MainContainerWrapper>
    </div>

}
export default HomeTestimonials