

import {memo} from "react";

const XIconGray = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1734_4014)">
                <g clipPath="url(#clip1_1734_4014)">
                    <rect width="25" height="25" rx="12.5" fill="#F7F4ED"/>
                </g>
                <path d="M15.1424 8.46055L12.5 11.1028L9.85768 8.46055C9.68792 8.29082 9.41271 8.29082 9.24296 8.46056L8.46068 9.24284C8.29094 9.41259 8.29094 9.6878 8.46067 9.85756L11.1029 12.4999L8.46067 15.1423C8.29094 15.312 8.29094 15.5872 8.46068 15.757L9.24296 16.5393C9.41271 16.709 9.68792 16.709 9.85768 16.5393L12.5 13.8971L15.1424 16.5393C15.3122 16.709 15.5874 16.709 15.7571 16.5393L16.5394 15.757C16.7091 15.5872 16.7091 15.312 16.5394 15.1423L13.8972 12.4999L16.5394 9.85756C16.7091 9.6878 16.7091 9.41259 16.5394 9.24284L15.7571 8.46056C15.5874 8.29082 15.3122 8.29082 15.1424 8.46055Z" fill="#7C764C"/>
            </g>
            <defs>
                <clipPath id="clip0_1734_4014">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
                <clipPath id="clip1_1734_4014">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default memo(XIconGray)