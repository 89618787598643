'use client'
import "@/app/styles/global.scss";
import {useRouter} from "next/navigation";

const HomeBannerMobile = ({ data }) => {

    const router = useRouter();

    const startImageStyle = {
        backgroundImage: `url('${data.homePage?.home_mobile_banner}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width: '100%',
        height: '757px',
    };

    const bottleImage = {
        backgroundImage: `url('${data.homePage?.animation_bottle}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width: '100%',
        height: '700px',
    };

    return (
        <div className={'flex flex-col items-center w-full pt-[35px]'} style={startImageStyle}>
            <div className={'w-full flex justify-center mt-[100px]'}>
                <img width={219} height={39} src={data?.homePage?.animation_crux_image}/>
            </div>
            <div className={'text-[#8F7E76] text-[16px] font-[300] leading-[51.5px] tracking-[8.64px]'}>{data?.homePage?.animation_ashwagandha}</div>
            <div>
                <img width={306} height={306} src={data.homePage?.animation_bottle}/>
            </div>
            <div className={'text-[#8F7E76] text-[14px] font-[700] leading-[16px] tracking-[4.2px] max-w-[224px] text-center mt-[10px]'}>{data?.homePage?.animation_text}</div>
            <button onClick={ () => router.push('/prodavnica')} className={'bg-cruxGreen rounded-[30px] px-[20px] py-[16px] text-[14px] text-white font-[800] leading-[16px] tracking-[4.2px] mt-[20px]'}>{data?.homePage?.animation_button_text}</button>
        </div>
    );
};

export default HomeBannerMobile;
