'use client'
import React, { useState, useEffect, useRef } from "react";
import CheckIcon from "@/app/components/icons/CheckIcon";
import "@/app/styles/fonts.scss";
import useWindowSize from "@/app/hooks/useWindowSize";
import {useRouter} from "next/navigation";

const HomeTryNow = ({ data }) => {
    const [isHovered, setIsHovered] = useState(true);
    const {isMobile} = useWindowSize();
    const router = useRouter();

    const observeDivRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    // Adjust according to the condition you care about (e.g., partially visible, fully visible)
                    if (entry.isIntersecting) {
                        setIsHovered(true);
                    }
                });
            },
            {
                // Options for the observer (e.g., root, rootMargin, threshold)
                threshold: 0.1, // Trigger when at least 10% of the observed element is visible
            }
        );

        if (observeDivRef.current) {
            observer.observe(observeDivRef.current); // Start observing the ref'd element
        }

        return () => {
            if (observeDivRef.current) {
                observer.unobserve(observeDivRef.current); // Stop observing on cleanup
            }
        };
    }, []);

    const items = [
        data.homePage.try_now_stres,
        data.homePage.try_now_snaga,
        data.homePage.try_now_energija,
        data.homePage.try_now_zdravlje,
        data.homePage.try_now_san,
    ];

    const bottleImageStyle = {
        backgroundImage: `url('${data.homePage?.try_now_bottle_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center", // Ovo kombinuje X i Y u jedan stil
        width: "280px", // Ovde ste možda želeli '300px' umesto '300px%'
        // height: isMobile ? "350px" : "500px",
        zIndex: 3,
        position: "relative",
        top: isMobile ? "-80px" : "",
    }
    return (
        <div className='flex flex-col text-center xsm:mt-[50px] xl:mt-[0px] xxl:mt-[200px] 2xl:mt-[100px]'>
            <div className='flex flex-col border-b-[1px] border-cruxBrown  xsm:w-fit lg:w-1/3 mx-[auto]'>
                <div className='xsm:text-[10px] font-[300] xsm:leading-[24px] xsm:mb-[14px] md:mb-[30px] md:text-[14px] text-[#626261] tracking-[6px] md:tracking-[8.4px]'>{data.homePage?.ashwagandha}</div>
                <h1 className='text-cruxGray tracking-[3.2px] md:tracking-[4.8]px xsm:text-[32px] xsm:leading-[24px] md:leading-[40px] lg:text-[40px] xxl:text-[48px] font-black'>{data.homePage?.try_now_title}</h1>
                <h2 className={'text-cruxGray tracking-[4.8px] font-[300] xsm:text-[24px] lg:text-[32px] xxl:text-[36px] md:leading-[40px] md:tracking-[7.2px]'}>{data.homePage?.try_now_subtitle}</h2>
            </div>
            <div className='flex xsm:flex-col sm:flex-col md:flex-col lg:flex-row gap-[120px] xsm:gap-0 lg:gap-[15%] xl:gap-[11%] 2xl:gap-[14%] 3xl:gap-[8%]'>
                {/*<div ref={observeDivRef} className="block md:hidden relative xsm:mt-[100px] xsm:mb-[0px] lg:mt-[100px] lg:mb-[100px] xsm:h-[240px] lg:h-[350px] xsm:w-1/2 lg:w-1/3 rounded-r-[30px] bg-goldGradient ">*/}
                {/*    <div className={`absolute top-0 xsm:left-[30%] lg:left-[50%] h-[350px] md:h-1/4 w-full bg-center bg-cover bg-no-repeat transition-all duration-[900ms] ease-out ${isHovered ? 'translate-x-0 3xl:translate-x-2/3' : 'scale-100 -translate-x-full opacity-0'}`} style={bottleImageStyle}></div>*/}
                {/*</div>*/}
                <div className={'block md:hidden w-full pt-[100px] pb-[50px]'}>
                    <div className={'bg-goldGradient2 rounded-r-[30px] h-[251px] w-1/2 flex justify-center items-center'}>
                        <img className={'absolute w-[90%] right-[20px] pt-[0px]'} src={data.homePage?.try_now_bottle_image} alt="Collagen Wake Up"/>
                    </div>
                </div>
                <div className="hidden md:block relative xsm:mt-[100px] xsm:mb-[0px] lg:mt-[100px] lg:mb-[100px] xsm:h-[300px] xxl:h-[350px] xsm:w-1/2 lg:w-[40%] xl:w-[45%] 3xl:w-[45%] rounded-r-[30px] bg-goldGradient "
                     onMouseEnter={() => setIsHovered(true)} >
                    <div className={`absolute lg:top-[-30px] xxl:top-[-80px] xsm:left-[30%] lg:left-[65%] xl:left-[50%] 4xl:left-[60%] h-[350px] lg:h-[350px] xxl:h-[500px] w-full bg-center bg-cover bg-no-repeat transition-all duration-[900ms] ease-out ${isHovered ? 'xl:translate-x-1/2 2xl:translate-x-[100%]' : 'scale-100 -translate-x-full opacity-0'}`} style={bottleImageStyle}></div>
                </div>
                <div className='flex flex-col xsm:my-[30px] lg:my-[100px] lg:w-1/3 xl:w-1/3 2xl:w-1/4 justify-between text-cruxGray'>
                    {items.map((item, index) => (
                        <div key={index} className='group relative flex items-start py-[10px] xsm:mx-auto lg:mx-0 overflow-hidden rounded-[30px]'>
                            <div className='absolute top-0 left-0 w-full h-full bg-goldGradient2 opacity-0 '></div>
                            <div className='flex flex-row items-center gap-[20px] min-h-[40px] z-10 relative'>
                                <CheckIcon />
                                <p className={'max-w-[200px] w-[200px] text-start'}>{item}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='border-t-[2px] border-cruxBrown xsm:w-fit lg:w-1/3 mx-auto'>
                {/*<p onClick={() => router.push('/refund_returns')} className='cursor-pointer xsm:text-[16px] w-[207px] md:w-full mx-auto  underline my-5 text-cruxGray'>{data.homePage?.try_now_povrat_novca} <strong className="font-black">{data.homePage?.try_now_prvih_14_dana}</strong></p>*/}
                <button onClick={()=>router.push('/prodavnica')} className='bg-cruxGreen rounded-[30px] font-[800] tracking-[1.2px] md:tracking-[1.6px] text-white px-[40px] md:px-[70px] py-[10px] md:py-[16px] mt-[14px]'>{data.homePage?.try_now_button}</button>
            </div>
        </div>
    );
}

export default HomeTryNow;

