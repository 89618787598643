'use client'
import "../../../styles/global.scss";
import "../../../styles/fonts.scss";
import ContainerWrapper from "@/app/components/common/ContainerWrapper";
import CheckIconWhite from "@/app/components/icons/CheckIconWhite";
import useWindowSize from "@/app/hooks/useWindowSize";

const HomeClinicalStudy = ({data}) =>{

    const {isMobile} = useWindowSize();

    return <div style={{ backgroundImage: isMobile ? `url('${data.homePage?.clinical_study_mobile_image}')` : `url('${data.homePage?.clinical_study_background_image}')` }} className="bg-cover bg-no-repeat bg-center my-[100px] xsm:h-[300px] lg:h-[100%] lg:mb-[10px] xxl:mb-[100px]">
    <ContainerWrapper>
        <div className='w-full mt-[50px]'>
            <div className='flex flex-col text-center text-cruxGray  xsm:w-full lg:w-full mx-auto xsm:mt-[-90px] md:mt-[-100px] mb-[100px]'>
                <div className='xsm:text-[10px] xsm:tracking-[6px] leading-[24px] lg:text-[14px] font-[300] tracking-[4px] md:tracking-[8.4px] mb-[14px] md:mb-[29px]'>{data.homePage?.clinical_study_ashwagandha}</div>
                <h1 className='font-black tracking-[2.4px] leading-[24px] xsm:text-[24px] font-black xsm:tracking-[2.4px] md:leading-[40px] md:tracking-[4.8px] md:text-[20pt] xl:text-[40px] xxl:text-[48px] text-cruxGray'>{data.homePage?.clinical_study_title}</h1>
                <h2 className='font-[300] tracking-[4px] leading-[24px] xsm:text-[20px] md:text-[30px] xsm:tracking-[4px] md:tracking-[7.2px] md:leading-[40px] xl:text-[30px] xxl:text-[36px] text-cruxGray'>{data.homePage?.clinical_study_subtitle}</h2>
                <div className={'block mt-[14px] border-b border-cruxGray w-[150px] md:w-[600px] mx-auto'}></div>
            </div>
            <div className='p-[30px] mx-auto lg:mx-[0px] bottom-[-150px] bg-cruxGreen rounded-[30px] xsm:w-full lg:w-1/2 h-fit lg:max-w-[400px] xl:max-w-[600px] max-h-[550px] my-[50px] text-[16px] xl:text-[20px] font-[300] text-center relative xsm:left-[0px] md:top-[0px] md:left-[-20px] xl:left-[0px]'>
                <div className='flex flex-col text-white mx-auto'>
                    <div className='flex flex-col mb-[30px]'>
                        <p className={' font-[300]'}>{data.homePage?.clinical_study_podrzava}</p>
                        <p className={'font-[700]'}>{data.homePage?.clinical_study_san}</p>
                    </div>
                    <div className='flex flex-col font-[300] text-center mx-auto gap-[8px] mb-[33px]'>
                        <div className='flex flex-row gap-3 items-center'>
                            <CheckIconWhite/>
                            <p className={'leading-[22px] text-start'}>{data.homePage?.clinical_study_uspavljivanje}</p>
                        </div>
                        <div className='flex flex-row gap-3 items-center'>
                            <CheckIconWhite/>
                            <p className={'leading-[22px] text-start'}>{data.homePage?.clinical_study_budnost}</p>
                        </div>
                        <div className='flex flex-row gap-3 items-center'>
                            <CheckIconWhite/>
                            <p className={'leading-[22px] text-start'}>{data.homePage?.clinical_study_spavanje}</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col font-[300] text-center text-white md:w-2/3 mx-auto'>
                    <p>{data.homePage?.clinical_study_poboljsanje}</p>
                    <p className='font-black text-[96px] xl:text-[100px] text-white'>{data.homePage?.clinical_study_procenat}</p>
                    <p className={'font-[300]'}>{data.homePage?.clinical_study_kvalitet_sna}</p>
                </div>
            </div>
        </div>
    </ContainerWrapper>
    </div>

}
export default HomeClinicalStudy