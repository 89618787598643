'use client'
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";
import {useRouter} from "next/navigation";

const HomeAcceptChallenge = ({data}) => {

    const router = useRouter();

    return(<MainContainerWrapper>
        <div className='hidden md:block bg-cruxGreen rounded-[30px] w-full h-[350px] py-[50px] mt-[100px] text-center text-white'>
            <div className='text-[42px] xxl:text-[48px] tracking-[4.8px] leading-[40px] font-[900] mt-[10px]'>{data?.homePage?.home_collect_title}</div>
            <div className='text-[28px] xxl:text-[32px] tracking-[6.4px] leading-[40px] font-[300] mb-[30px]'>{data.homePage.home_collect_subtitle}</div>
            <p className='max-w-[680px] text-[18px] xxl:text-[20px] leading-[28px] font-[300] mx-auto mb-[20px]'>{data.homePage.home_collect_text}</p>
            {/*<button onClick={() => router.push('/accept-challenge')} className='bg-white rounded-[30px] px-[50px] py-[12px] tracking-[1.6px] text-cruxGreen font-[900]'>{data.homePage.home_collect_button}</button>*/}
            <a href="/accept-challenge" className="bg-white rounded-[30px] px-[50px] py-[12px] tracking-[1.6px] text-cruxGreen font-[900]">
                {data.homePage.home_collect_button}
            </a>
        </div>
        <div className='block md:hidden bg-cruxGreen rounded-[30px] w-full h-fit py-[50px] mt-[100px] text-center text-white'>
            <div className='text-[20px] tracking-[1px] leading-[22px] font-[900] mt-[10px]'>{data?.homePage?.home_collect_title}</div>
            <div className='text-[20px] tracking-[1px] leading-[22px] font-[400] mb-[30px]'>{data.homePage.home_collect_subtitle}</div>
            <p className='text-[16px] mx-auto mb-[20px] font-[300]'>{data.homePage.home_collect_text}</p>
            {/*<button onClick={() => router.push('/accept-challenge')} className='bg-white rounded-[30px] px-[50px] py-[12px] text-cruxGreen tracking-[1.2px] font-[900]'>{data.homePage.home_collect_button}</button>*/}
            <a href="/accept-challenge" className="bg-white rounded-[30px] px-[50px] py-[12px] text-cruxGreen font-[800]">
                {data.homePage?.home_collect_button}
            </a>
        </div>
    </MainContainerWrapper>)

}
export default  HomeAcceptChallenge