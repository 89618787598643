'use client'
import ContainerWrapper from "@/app/components/common/ContainerWrapper";
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";
import useWindowSize from "@/app/hooks/useWindowSize";
import CheckIconGold from "@/app/components/icons/CheckIconGold";
import XIconGray from "@/app/components/icons/XIconGray";
import {hidden} from "next/dist/lib/picocolors";
import React from "react";

import "@/app/styles/global.scss";
import { Inter } from "next/font/google";
import {useRouter} from "next/navigation";
const inter = Inter({ subsets: ["latin"] });

const HomeSupport = ({data}) => {

    const {isMobile} = useWindowSize()
    const router = useRouter();

    const imageStyle = {
        backgroundImage: `url('${data.homePage?.support_background_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width:"100%",
        height: !isMobile && "1533px",
    }

    const image2Style = {
        backgroundImage: `url('${data.homePage?.support_diagram_image}')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width:"100%",
        height: "500px",
    }

    return <div style={imageStyle} className='bg-cruxBeige mb-[0px] 2xl:mb-[300px]'>
        <ContainerWrapper>
            <div className='flex flex-col w-full xsm:gap-[50px] lg:gap-0 xxl:gap-[100px] xsm:mt-[350px] lg:mt-[30px]'>
                <div className='flex flex-col text-center text-white xsm:w-full mx-auto'>
                    <div className='xsm:text-[10px] mt-[70px] md:mt-[0px] text-cruxGray xsm:tracking-[6px] leading-[24px] lg:text-[14px] font-[300] tracking-[4px] md:tracking-[8.4px] mb-[14px] md:mb-[29px]'>{data.homePage?.support_ashwagandha}</div>
                    <h1 className='text-cruxGray xsm:text-[24px] xsm:leading-6 md:leading-[40px] font-black lg:text-[20pt] md:tracking-[4.8px] xl:text-[40px] xxl:text-[48px]'>{data.homePage.support_title}</h1>
                    <h2 className='text-cruxGray font-[300] xsm:text-[20px] xsm:leading-[24px] md:leading-[40px] xsm:tracking-[4px] md:tracking-[7.2px] text-[18pt] xl:text-[30px] xxl:text-[36px]' dangerouslySetInnerHTML={{__html: data.homePage.support_subtitle}}></h2>
                    <span className={'border-b border-cruxGray mt-[12px] xsm:w-[150px] lg:w-[600px] mx-auto'}></span>
                </div>
                <div className={'hidden lg:block max-w-[1200px] md:mx-auto'} style={image2Style}>
                </div>
                <div className={'flex items-center mb-[60px] lg:hidden flex-col w-full mx-auto gap-3.5'}>
                    <img width={"280px"} src={data?.homePage?.home_support_diagram_mobile1}/>
                    <img width={"280px"} src={data?.homePage?.home_support_diagram_mobile2}/>
                </div>
                <div className={'xsm:hidden md:flex justify-center items-center bg-cruxGreen mx-auto z-50 rounded-[30px] max-w-[1200px] w-full 2xl:w-[1200px] h-[500px] xxl:h-[575px] lg:px-[80px] xl:px-[100px] xxl:px-[50px] 2xl:px-[120px] 3xl:px-[150px] py-[50px]'}>
                    <div
                        className={'h-[350px] xxl:h-[500px] lg:w-[40%]'}
                        style={{
                            backgroundImage: `url('${data.homePage?.home_support_bottle_image}')`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            // width: "42%",
                            // height:"auto",
                        }}
                    ></div>
                    <div className={'flex flex-row w-full justify-evenly'}>
                        <div className={'flex flex-col text-[16px] xxl:text-[20px] gap-[10px] xxl:gap-[20px] font-[300] justify-evenly text-white'}>
                            <div className={'opacity-0 h-[105px]'} dangerouslySetInnerHTML={{ __html: data?.homePage?.comparation_title1}}></div>
                            <p>{data?.homePage?.comparation_text1}</p>
                            <p>{data?.homePage?.comparation_text2}</p>
                            <p>{data?.homePage?.comparation_text3}</p>
                            <p>{data?.homePage?.comparation_text4}</p>
                            <p>{data?.homePage?.comparation_text5}</p>
                            <p>{data?.homePage?.comparation_text6}</p>
                            <p>{data?.homePage?.comparation_text7}</p>
                        </div>
                        <div className={'flex items-center text-[16px] xxl:text-[20px] gap-[10px] xxl:gap-[20px] font-[300] text-center flex-col justify-evenly text-white'}>
                            {/*<div className={inter.className} dangerouslySetInnerHTML={{ __html: data?.homePage?.comparation_title1}}></div>*/}
                            <img className={'h-[105px]'} src={data.homePage?.home_support_bottle_image}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gold_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gold_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gold_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gold_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gold_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gray_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gray_icon}/>
                        </div>
                        <div className={'flex items-center text-[16px] xxl:text-[20px] gap-[10px] xxl:gap-[20px] font-[300] text-center flex-col justify-evenly text-white'}>
                            {/*<div className={inter.className} dangerouslySetInnerHTML={{ __html: data?.homePage?.comparation_title2}}></div>*/}
                            <img className={'h-[105px] w-[66px]'} src={data.homePage?.fake_asw_img}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gray_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gray_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gray_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gray_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gray_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gold_icon}/>
                            <img className={'w-[25px] h-[25px] xxl:w-[35px] xxl:h-[35px]'} src={data.homePage?.gold_icon}/>
                        </div>
                    </div>
                </div>
                <div className={'flex lg:hidden justify-center bg-cruxGreen z-[2] text-white rounded-tl-[50%] rounded-[10px] p-[10px] -mx-[8.5%]'}>
                    <div className={'flex flex-col justify-evenly h-[500px] text-[16px]'}>
                        <div className={'flex justify-end mr-[-12px]'}>
                            <div></div>
                            <img className={'w-[80px] h-[80px]'} src={data.homePage?.home_support_bottle_image} />
                            <img className={'w-[50px] h-[80px] ml-[10px]'} src={data.homePage?.real_asw_image} />
                            {/*<div className={'text-[13px] font-[400] leading-[16px] text-center items-center mt-[15px]'}>*/}
                            {/*    <div className={inter.className} dangerouslySetInnerHTML={{ __html: data?.homePage?.comparation_title2_mobile}}></div>*/}
                            {/*</div>*/}
                        </div>
                        <div className={'flex justify-end'}>
                            <p className={'w-[175px] mr-[10px] text-left'}>{data?.homePage?.comparation_text1}</p>
                            <img width="25px" height="25px" src={data.homePage?.gold_icon}/>
                            <img className={'ml-[50px]'} width={25} height={25} src={data.homePage?.gray_icon}/>
                        </div>
                        <div className={'flex justify-end'}>
                            <p className={'w-[175px] mr-[10px]  text-left'}>{data?.homePage?.comparation_text2}</p>
                            <img width={25} height={25} src={data.homePage?.gold_icon}/>
                            <img className={'ml-[50px]'} width={25} height={25} src={data.homePage?.gray_icon}/>
                        </div>
                        <div className={'flex justify-end'}>
                            <p className={'w-[175px] mr-[10px]  text-left'}>{data?.homePage?.comparation_text3}</p>
                            <img width={25} height={25} src={data.homePage?.gold_icon}/>
                            <img className={'ml-[50px]'} width={25} height={25} src={data.homePage?.gray_icon}/>
                        </div>
                        <div className={'flex justify-end'}>
                            <p className={'w-[175px] mr-[10px]  text-left'}>{data?.homePage?.comparation_text4}</p>
                            <img width={25} height={25} src={data.homePage?.gold_icon}/>
                            <img className={'ml-[50px]'} width={25} height={25} src={data.homePage?.gray_icon}/>
                        </div>
                        <div className={'flex justify-end'}>
                            <p className={'w-[175px] mr-[10px]  text-left'}>{data?.homePage?.comparation_text5}</p>
                            <img width={25} height={25} src={data.homePage?.gold_icon}/>
                            <img className={'ml-[50px]'} width={25} height={25} src={data.homePage?.gray_icon}/>
                        </div>
                        <div className={'flex justify-end'}>
                            <p className={'w-[175px] mr-[10px]  text-left'}>{data?.homePage?.comparation_text6}</p>
                            <img width={25} height={25} src={data.homePage?.gray_icon}/>
                            <img className={'ml-[50px]'} width={25} height={25} src={data.homePage?.gold_icon}/>
                        </div>
                        <div className={'flex justify-end'}>
                            <p className={'w-[175px] mr-[10px]  text-left'}>{data?.homePage?.comparation_text7}</p>
                            <img width={25} height={25} src={data.homePage?.gray_icon}/>
                            <img className={'ml-[50px]'} width={25} height={25} src={data.homePage?.gold_icon}/>
                        </div>
                    </div>
                </div>
                {/*<div style={{ backgroundImage: `url('${data.homePage?.support_statistics_image}')` }} className="bg-cover bg-no-repeat bg-center bg-top w-full h-[450px] xl:h-[600px] 2xl:h-[700px] 3xl:h-[800px] relative top-[-50px] z-20 rounded-[30px]">*/}
                {/*</div>*/}
            </div>
        </ContainerWrapper>
        <MainContainerWrapper>
            <div className='w-full  lg:h-[500px] bg-white rounded-[30px] xsm:-mt-[30px] lg:-mt-[320px] xxl:-mt-[350px] 2xl:-mt-[350px]'>
                <ContainerWrapper>
                <div className='flex flex-row md:w-full md:justify-center xsm:text-[12px] md:text-[16px] mx-auto py-[20%] md:mt-[240px] lg:mt-[220px] xl:mt-[180px] 2xl:mt-[140px] 3xl:mt-[120px] 4xl:mt-[0px] font-thin items-center gap-2.5 md:gap-[77px]'>
                    {/*<div onClick={() => router.push('/refund_returns')} className='cursor-pointer  h-[80px] md:h-[100px]  text-center flex flex-col justify-between items-center'>*/}
                    {/*    <img src={data.homePage?.support_image_repeater[0]?.support_return_image} width={isMobile ? '26.26px' : '60px'} height={isMobile ? '24px' :'60px'} alt="Return Policy"/>*/}
                    {/*    <p className=' text-[#777169] font-[400] leading-[13px] max-w-[81px] md:max-w-[150px]'>{data.homePage.support_garancija}</p>*/}
                    {/*</div>*/}
                    {/*<div className={'border-l h-[60px] md:h-[100px] border-cruxGreen gap-[8px] md:gap-[77px]'}></div>*/}
                    <div onClick={() => router.push('/dostava-i-postarina')} className='cursor-pointer  h-[80px] md:h-[100px] text-center flex flex-col md:flex-row justify-between items-center gap-[20px]'>
                        <img src={data.homePage?.support_image_repeater[0]?.support_delivery_image} width={isMobile ? '40.26px' : '80px'} height={isMobile ? '28px' :'60px'} alt="Return Policy"/>
                        <p className={'text-[#777169] font-[400] leading-[13px]'}>{data.homePage.support_isporuka.toUpperCase()}</p>
                    </div>
                    <div className={'border-r h-[60px] md:h-[100px] border-cruxGreen gap-[8px] md:gap-[77px]'}></div>
                    <div onClick={() => router.push('/politika-privatnosti')} className='cursor-pointer  h-[80px] md:h-[100px] items-center text-center flex flex-col md:flex-row justify-between gap-[20px]'>
                        <img src={data.homePage?.support_image_repeater[0]?.support_security_image} width={isMobile ? '26.26px' : '60px'} height={isMobile ? '24px' :'50px'} alt="Return Policy"/>
                        <p className={'text-[#777169] font-[400] leading-[13px]'}>{data.homePage.support_sigurna_kupovina.toUpperCase()}</p>
                    </div>
                </div>
                </ContainerWrapper>
            </div>
        </MainContainerWrapper>
    </div>

}
export default HomeSupport