import {useEffect, useState} from "react";

const mobileBP = 768;
const tabletMini = 768;

const tabletBP = 1024;


const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        isMobile: false,
        isTablet: false,
        isDesktop: false,
    });
    //
    // useEffect(() => {
    //     // only execute all the code below in client side
    //     // Handler to call on window resize
    //     const handleResize = () => {
    //         // Set window width/height to state
    //         setWindowSize({
    //             width: window.innerWidth,
    //             height: window.innerHeight,
    //             isMobile: window.innerWidth <= 768,
    //             isDesktop: window.innerWidth > 768
    //         });
    //     }

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setWindowSize({
                width,
                height: window.innerHeight,
                isMobile: width <= mobileBP,
                isTablet: width > mobileBP && width <= tabletBP,
                isDesktop: width > tabletBP,
            });
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


export default useWindowSize;