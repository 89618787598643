'use client'
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";
import ContainerWrapper from "@/app/components/common/ContainerWrapper";
import useWindowSize from "@/app/hooks/useWindowSize";

const HomeWhatIsIn = ({data}) => {

    const {isMobile} = useWindowSize();

    return <MainContainerWrapper>
        <div className='bg-cruxGreen w-full xsm:py-[50px] xsm:rounded-[30px] md:rounded-[0px]  lg:py-[160px]'>
            <div className='flex flex-col text-center text-white w-full mx-auto'>
                <div className='text-[10px] mb-[14px] md:mb-[29px] xl:text-[12pt] font-[300] tracking-[6px] leading-[24px]'>{data?.homePage?.what_is_in_ashwagandha}</div>
                <div className='text-white font-black tracking-[3.2px] md:tracking-[4.8px] leading-[24px] text-[20pt] xl:text-[26pt]'>{data?.homePage?.what_is_in_title}</div>
                <div className='text-white font-thin md:py tracking-[4.8px] md:tracking-[7.2px] leading-[24px] md:leading-[40px] text-[16pt] xl:text-[20pt] '>{data?.homePage?.what_is_in_subtitle}</div>
                <div className="border-b border-white mx-auto w-[150px] mt-[14px] md:w-[40%]"></div>
            </div>
            <ContainerWrapper>
                <div className='flex xsm:flex-col lg:flex-row text-white my-[50px] gap-[40px] lg:gap-[20px] xxl:gap-[40px] text-center'>
                    <div className='flex flex-col gap-2 max-w-[350px] items-center'>
                        <img width="70px" height="70px" src={data.homePage?.packaging[0]?.packaging_image}/>
                        <div className='tracking-[1px] w-full xl:text-[18pt] '>
                            <div className=' text-[14px] xxl:text-[20px] leading-[22px] font-black mt-[12px]'>{data.homePage.packaging[0].staklena}</div>
                            <div className={'text-[14px] xxl:text-[20px] font-[400] leading-[22px]'}>{data.homePage.packaging[0].ambalaza}</div>
                            <div className={'block mt-[12px] mb-[20px] border-b-2 border-cruxGold w-[150px] lg:w-[200px] xxl:w-[250px] mx-auto'}></div>
                        </div>
                        <p className={'font-[300] text-[16px] leading-[24px]'}>{data.homePage.packaging[0].packaging_text}</p>
                    </div>
                    <div  className='flex flex-col gap-2 max-w-[350px] items-center'>
                        <img width="70px" height="70px" src={data.homePage?.ksm_66[0]?.ksm_image}/>
                        <div className='tracking-[1px] xl:text-[18pt]'>
                            <div className='text-[14px] xxl:text-[20px] leading-[22px] font-black mt-[12px]'>{data.homePage.ksm_66[0].ksm_66_ashwagandha}</div>
                            <div className={'text-[14px] xxl:text-[20px] font-[400] leading-[22px]'}>{data.homePage.ksm_66[0].world_no1}</div>
                            <div className={'block mt-[12px] mb-[20px] border-b-2 border-cruxGold w-[150px] lg:w-[200px] xxl:w-[250px] mx-auto'}></div>
                        </div>
                        <p className={'font-[300] text-[16px] leading-[24px]'}>{data.homePage.ksm_66[0].ksm_text}</p>
                    </div>
                    <div  className='flex flex-col gap-2 max-w-[350px] items-center'>
                        <img width="70px" height="70px" src={data.homePage?.vegan[0]?.vegan_image}/>
                        <div className='tracking-[1px] w-full xl:text-[18pt] '>
                            <div className='text-[14px] xxl:text-[20px] leading-[22px] font-black mt-[12px]'>{data.homePage.vegan[0].veganske}</div>
                            <div className={'text-[14px] xxl:text-[20px] font-[400] leading-[22px]'}>{data.homePage.vegan[0].kapsule}</div>
                            <div className={'block mt-[12px] mb-[20px] border-b-2 border-cruxGold w-[150px] lg:w-[200px] xxl:w-[250px] mx-auto'}></div>
                        </div>
                        <p className={'font-[300] text-[16px] leading-[24px]'}>{data.homePage.vegan[0].vegan_text}</p>
                    </div>
                </div>
                <div className={'hidden md:flex xl:flex-row gap-[20px] 2xl:gap-[25px]'}>
                    <div className={'flex gap-[20px] 2xl:gap-[25px]'}>
                        <img className={'lg:w-[100px] xl:w-[120px] xxl:w-[150px]'} width={150} height={150} src={data?.homePage?.what_is_in_image1}/>
                        <img className={'lg:w-[100px] xl:w-[120px] xxl:w-[150px]'} width={150} height={150} src={data?.homePage?.what_is_in_image2}/>
                        <img className={'lg:w-[100px] xl:w-[120px] xxl:w-[150px]'} width={150} height={150} src={data?.homePage?.what_is_in_image3}/>
                    </div>
                    <div className={'flex gap-[20px] 2xl:gap-[25px]'}>
                        <img className={'lg:w-[100px] xl:w-[120px] xxl:w-[150px]'} width={150} height={150} src={data?.homePage?.what_is_in_image4}/>
                        <img className={'lg:w-[100px] xl:w-[120px] xxl:w-[150px]'} width={150} height={150} src={data?.homePage?.what_is_in_image5}/>
                        <img className={'lg:w-[100px] xl:w-[120px] xxl:w-[150px]'} width={150} height={150} src={data?.homePage?.what_is_in_image6}/>
                    </div>
                </div>
                <div className={'flex md:hidden flex-col gap-[25px]'}>
                    <div className={'flex gap-[25px]'}>
                        <img width={50} height={50} src={data?.homePage?.what_is_in_image1}/>
                        <img width={50} height={50} src={data?.homePage?.what_is_in_image2}/>
                        <img width={50} height={50} src={data?.homePage?.what_is_in_image3}/>
                    </div>
                    <div className={'flex gap-[25px]'}>
                        <img width={50} height={50} src={data?.homePage?.what_is_in_image4}/>
                        <img width={50} height={50} src={data?.homePage?.what_is_in_image5}/>
                        <img width={50} height={50} src={data?.homePage?.what_is_in_image6}/>
                    </div>
                </div>
            </ContainerWrapper>
        </div>
    </MainContainerWrapper>

}
export default HomeWhatIsIn