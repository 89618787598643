'use client'
import styles from "@/app/styles/components/pages/home/home-day-medicine.module.scss";
import ContainerWrapper from "@/app/components/common/ContainerWrapper";
import useWindowSize from "@/app/hooks/useWindowSize";

const HomeDayMedicine = ({data}) => {

    const {isMobile} = useWindowSize();

    const imageStyle = {
        backgroundImage: `url('${data.homePage?.day_medicine_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        margin: "0 auto",
        width:"90%",
        // height: "956px",
        borderRadius: "30px",

    }
    //lg:-mt-[200px] xl:-mt-[100px] 2xl:-mt-[200px] 3xl:-mt-[200px]
    const imageStyleMobile = {
        backgroundImage: `url('${data.homePage?.day_medicine_image_mobile}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        margin: "0 auto",
        width:"100%",
        // height: "500px",
    }

    return <div className={'xsm:h-[500px] lg:h-[700px] xxl:h-[1000px]'} style={isMobile ? imageStyleMobile : imageStyle}>
        <div className={'xsm:mt-[260px] lg:mt-[150px] xl:mt-[100px] 2xl:mt-[100px] 3xl:mt-[100px]'}>
        <ContainerWrapper>
            <div className={'flex flex-col relative top-[-100px] lg:top-[-150px] xxl:top-[-100px] xsm:mb-[70px] lg:mb-[100px] lg:pt-[30px] xl:pt-[30px]  lg:mx-[-15%] 2xl:mx-auto text-center lg:border-b lg:border-t lg:border-cruxGray'}>
                <span className={'block lg:hidden border-b border-cruxGray w-[150px] mx-auto'}/>
                <div className={'text-[40pt] xsm:text-[24px] xsm:leading-[30px] lg:text-[56px] xxl:text-[72px] font-thin text-cruxGray mt-[40px]'}>{data.homePage?.title_day_medicine}</div>
                <div className={'text-[40pt] xsm:text-[24px] xsm:leading-[30px] tracking-[2.4px] md:tracking-[6.4px] lg:text-[56px] xxl:text-[72px] text-cruxGray font-[400] xsm:mb-[5px] lg:mb-[20px] lg:mt-[50px]'}>{data.homePage?.subtitle_day_medicine}</div>
                <p className={'italic xsm:text-[16px] xsm:leading-[28px] lg:text-[16px] font-thin text-cruxGray mb-[20px]'}>{data.homePage?.autor}</p>
                <span className={'block lg:hidden border-b border-cruxGray w-[150px] mx-auto'}/>
            </div>
            <div className={'hidden md:flex flex-row  justify-between w-full max-w-[1200px] lg:mt-[-100px]  xxl:mt-[100px] 2xl:mt-[0px] text-cruxBrown lg:h-[400px] xxl:h-[500px]'}>
                <div className={'flex flex-col justify-evenly mx-[-5%] 2xl:mx-[0px]'}>
                    <p className={styles.textFiled}>{data.homePage?.day_medicine_repeater_left[0].stres}</p>
                    <p className={styles.textFiled}>{data.homePage?.day_medicine_repeater_left[0].san}</p>
                    <p className={styles.textFiled}>{data.homePage?.day_medicine_repeater_left[0].tezina}</p>
                    <p className={styles.textFiled}>{data.homePage?.day_medicine_repeater_left[0].fokus}</p>
                </div>
                <div className={'flex flex-col justify-evenly mx-[-5%] 2xl:mx-[0px]'}>
                    <p className={styles.textFiled}>{data.homePage?.day_medicine_repeater_right[0].zlezda}</p>
                    <p className={styles.textFiled}>{data.homePage?.day_medicine_repeater_right[0].snaga}</p>
                    <p className={styles.textFiled}>{data.homePage?.day_medicine_repeater_right[0].odrzavanje}</p>
                    <p className={styles.textFiled}>{data.homePage?.day_medicine_repeater_right[0].funkcije}</p>
                </div>
            </div>
            <div className={'flex md:hidden flex-col text-[10px] text-cruxBrown'}>
                <div className={'flex flex-col gap-[10px] relative top-[-150px]'}>
                    <div className={'flex flex-row gap-[20px]'}>
                        <p className={styles.textFiledMobile}>{data.homePage?.day_medicine_repeater_left[0].stres}</p>
                        <p className={styles.textFiledMobile}>{data.homePage?.day_medicine_repeater_left[0].san}</p>
                    </div>
                    <div className={'flex flex-row gap-[20px]'}>
                        <p className={styles.textFiledMobile}>{data.homePage?.day_medicine_repeater_left[0].tezina}</p>
                        <p className={styles.textFiledMobile}>{data.homePage?.day_medicine_repeater_left[0].fokus}</p>
                    </div>
                </div>
                <div className={'flex flex-col gap-[10px] relative bottom-[-130px]'}>
                    <div className={'flex flex-row gap-[20px]'}>
                        <p className={styles.textFiledMobile}>{data.homePage?.day_medicine_repeater_right[0].zlezda}</p>
                        <p className={styles.textFiledMobile}>{data.homePage?.day_medicine_repeater_right[0].snaga}</p>
                    </div>
                    <div className={'flex flex-row gap-[20px]'}>
                        <p className={styles.textFiledMobile}>{data.homePage?.day_medicine_repeater_right[0].odrzavanje}</p>
                        <p className={styles.textFiledMobile}>{data.homePage?.day_medicine_repeater_right[0].funkcije}</p>
                    </div>
                </div>
            </div>
        </ContainerWrapper>
        </div>
    </div>

}
export default HomeDayMedicine