'use client'
import "../../../styles/global.scss";
import "../../../styles/fonts.scss";
import ArrowDownIcon from "@/app/components/icons/ArrowDownIcon";
import ContainerWrapper from "@/app/components/common/ContainerWrapper";
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";
import { Inter } from "next/font/google";
import "@/app/styles/global.scss";
import useWindowSize from "@/app/hooks/useWindowSize";
import ArrowDownIconWhite from "@/app/components/icons/ArrowDownIconWhite";
import React from "react";
import {useRouter} from "next/navigation";

const inter = Inter({ subsets: ["latin"] });

const HomeBaseInfo = ({data}) => {

    const {isMobile} = useWindowSize()
    const router = useRouter();

    const imageStyle = {
        backgroundImage:`url('${data.homePage?.home_base_info_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "bottom",
        width:"100%",
        // height: '2600px'
    }

    const imageStyleMobile = {
        backgroundImage: `url('${data.homePage?.home_mobile_base_info_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "bottom",
        width:"100%",
        height: '2280px'
    }

    return ( <div style={ isMobile ? imageStyleMobile : imageStyle}>
        <ContainerWrapper>
        <div className={'mt-[80px] xsm:mb-[37px] lg:mb-[70px] xxl:mb-[100px] flex flex-col justify-center items-center '}>
            <p className='block lg:hidden text-[#777777] text-[10px] tracking-[6px] mb-[5px] leading-[24px]'>{data?.homePage?.home_base_info_asw_label}</p>
            <h1 className='xsm:text-[24px] xsm:text-[#6D6662] xsm:leading-[24px] xsm:tracking-[2.4px] md:tracking-[3.2px] md:text-cruxGreen lg:text-[24px] xxl:text-[32px] font-black xsm:text-center sm:text-center md:leading-[32px]'>{data.homePage.title.toUpperCase()}</h1>
            <h2 className='xsm:text-[14px] xl:text-[18px] xxl:text-[24px] xsm:text-center xsm:text-[#6D6662] xsm:tracking-[4.2px] md:tracking-[8.4px] lg:text-cruxGreen sm:text-center  xsm:leading-[24px] md:leading-[32px]'>{data.homePage.subttile.toUpperCase()}</h2>
            <span className={'border-b-cruxBrown xsm:mt-[9px] xsm:leading-[22px] border-b w-[150px] md:w-full'}></span>
        </div>
        <div className={'w-full mx-auto md:px-[120px]'}>
            <div className="video-container relative overflow-hidden md:rounded-[30px] mb-[70px] lg:mb-[80px] xxl:mb-[100px] w-full max-w-[950px] pt-[56.25%] xxl:pt-[56%] 2xl:pt-[48.5%] 3xl:pt-[44%] mx-auto" >
                <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/Dxu1UfwWzd8?rel=0&autoplay=1&mute=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
        <div className="flex justify-evenly lg:justify-center lg:gap-[56px] xxl:gap-[86px] xsm:flex-col sm:flex-col xsm:text-center lg:flex-row  text-cruxGray xxl:w-[1200px] mb-[10%]">
            <div className="mb-[32px]">
                <div className="flex xsm:flex-col md:flex-row justify-center md:border-b-[1px] md:border-b-cruxBrown  md:pb-[15px] gap-x-[15px] xsm:my-[10px] md:mb-[22px] items-center">
                    <div
                        style={{
                            backgroundImage: `url('${data.homePage?.opusti_se[0]?.icon}')`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: isMobile ? "40px" : "52px",
                            height: isMobile ? "45px" : "60px",
                        }}
                    />
                    <div className={'flex flex-col md:text-start pt-[10px] leading-[22px] text-cruxGray tracking-[1px]'}>
                        <h3 className='text-[15pt] font-black xxl:text-[24px] md:text-cruxGreen md:leading-[30px]'>{data.homePage?.opusti_se[0]?.title}</h3>
                        <h4 className='text-[17px] xxl:text-[15pt] font-thin font-[400] xxl:text-[24px] md:text-cruxGreen md:leading-[30px]'>{data.homePage?.opusti_se[0]?.subtitle}</h4>
                        <div className={'block md:hidden mb-[16px] pb-[10px] border-b-[1px] border-b-cruxBrown w-[150px] mx-auto'}></div>
                    </div>
                </div>
                <div className={inter.className}>
                <div className='text-[16px] w-[310px] lg:w-[260px] xl:w-[260px] xxl:w-[310px] mx-auto text-center md:text-start xxl:text-[20px]'>{data.homePage?.opusti_se[0]?.description}</div>
                </div>
            </div>
            <div className="mb-[32px]">
                <div className="flex  xsm:flex-col md:flex-row  justify-center md:border-b-[1px] md:border-b-cruxBrown md:pb-[15px] gap-x-[15px] xsm:my-[10px] md:mb-[22px]  items-center">
                    <div
                        style={{
                            backgroundImage: `url('${data.homePage?.poboljsaj_kvalitet_sna[0].icon}')`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: isMobile ? "40px" : "52px",
                            height: isMobile ? "45px" : "60px",
                        }}
                    />
                    <div className={'flex flex-col  md:text-start pt-[10px] leading-[22px] text-cruxGray md:text-cruxGreen tracking-[1px]'}>
                        <h3 className='text-[15pt] font-black md:leading-[30px] xxl:text-[24px] md:leading-[30px] md:tracking-[1.2px]'>{data.homePage?.poboljsaj_kvalitet_sna[0]?.title}</h3>
                        <h4 className='text-[17px] xxl:text-[15pt] font-[400] md:leading-[30px] xxl:text-[24px] md:leading-[30px] md:tracking-[1.2px]'>{data.homePage?.poboljsaj_kvalitet_sna[0]?.subtitle}</h4>
                        <div className={'block md:hidden mb-[16px] pb-[10px] border-b-[1px] border-b-cruxBrown w-[150px] mx-auto'}></div>
                    </div>
                </div>
                <div className={inter.className}>
                <div className='text-[16px] w-[310px] lg:w-[260px] xl:w-[260px] xxl:w-[310px] mx-auto text-center md:text-start xxl:text-[20px]'>{data.homePage?.poboljsaj_kvalitet_sna[0]?.description}</div>
                </div>
            </div>
            <div className="mb-[32px]">
                <div className="flex  xsm:flex-col md:flex-row justify-center md:border-b-[1px] md:border-b-cruxBrown md:pb-[15px] gap-x-[15px] xsm:my-[10px] md:mb-[22px] items-center">
                    <div
                        style={{
                            backgroundImage: `url('${data.homePage?.povecaj_fokus_i_memoriju[0].icon}')`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: isMobile ? "40px" : "52px",
                            height: isMobile ? "45px" : "60px",
                        }}
                    />
                    <div className={'flex flex-col md:text-start pt-[10px] leading-[22px] text-cruxGray md:text-cruxGreen tracking-[1px] md:tracking-[1.2px]'}>
                        <h3 className='text-[15pt] font-black xxl:text-[24px] md:leading-[30px]'>{data.homePage?.povecaj_fokus_i_memoriju[0]?.title}</h3>
                        <h4 className='text-[17px] xxl:text-[15pt] font-[400] xxl:text-[24px] md:leading-[30px]'>{data.homePage?.povecaj_fokus_i_memoriju[0]?.subtitle}</h4>
                        <div className={'block md:hidden mb-[16px] pb-[10px] border-b-[1px] border-b-cruxBrown w-[150px] mx-auto'}></div>
                    </div>
                </div>
                <div className={inter.className}>
                <div className='text-[16px] w-[310px] lg:w-[260px] xl:w-[260px] xxl:w-[310px] mx-auto text-center md:text-start xxl:text-[20px]'>{data.homePage?.povecaj_fokus_i_memoriju[0]?.description}</div>
                </div>
            </div>
        </div>
            {!isMobile ?
                <div className={'w-full max-w-[1200px] bg-[#F7F4ED] rounded-[30px] px-[50px] xxl:px-[150px] py-[100px] relative z-33'}>
                    <div className={'w-3/4 mx-auto border-b border-cruxBrown mb-[60px]'}>
                        <h2 className={"text-center text-[16pt] xl:text-[20px] xxl:text-[24px] text-cruxGreen font-[300] leading-[32px] tracking-[2.4px]"}>{data.homePage.study_title}</h2>
                        <div className={'text-center tracking-[3.6px] text-[19pt] xl:text-[30px] xxl:text-[36px] font-black leading-[40px] mb-[18px] text-cruxGray'}>{data.homePage.subtitle_study}</div>
                    </div>
                    <div>
                        <div className={'flex flex-row justify-evenly'}>
                            <ul className={'flex flex-col justify-between items-center'}>
                                <li className={'flex flex-col items-center'}>
                                    <div className={'flex flex-row items-center justify-center max-w-[300px] border-b border-cruxBrown pb-[8px] mb-[10px] gap-[10px]'}>
                                    <ArrowDownIcon />
                                        <h1 className={'text-[36pt] lg:text-[54px] xxl:text-[64px] font-[700] leading-[35px] text-cruxGreen'}>{data.homePage.anksioznost[0].procenat}</h1>
                                    </div>
                                    <p className={'text-center text-[#777169] max-w-[250px] lg:text-[18px] xxl:text-[20px] font-[300] leading-[28px]'}>{data.homePage.anksioznost[0].title}</p>
                                </li>
                                <li>
                                    <div className={'flex flex-row items-center justify-center max-w-[300px] border-b border-cruxBrown pb-[8px] mb-[10px] gap-[10px]'}>
                                        <ArrowDownIcon />
                                        <h1 className={'text-[36pt] lg:text-[54px] xxl:text-[64px] font-[700] leading-[35px] text-cruxGreen'}>{data.homePage.prisutnost_stresa[0].procenat}</h1>
                                    </div>
                                    <p className={'text-center text-[#777169] max-w-[250px] lg:text-[18px] xxl:text-[20px] font-[300] leading-[28px]'}>{data.homePage.prisutnost_stresa[0].title}</p>
                                </li>
                                <li>
                                    <div className={'flex flex-row items-center justify-center border-b border-cruxBrown pb-[8px] mb-[10px] gap-[10px]'}>
                                        <ArrowDownIcon />
                                        <h1 className={'text-[36pt] lg:text-[54px] xxl:text-[64px] font-[700] leading-[35px] text-cruxGreen'}>{data.homePage.depresija[0].procenat}</h1>
                                    </div>
                                    <p className={'text-center text-[#777169] max-w-[250px] lg:text-[18px] xxl:text-[20px] font-[300] leading-[28px]'}>{data.homePage.depresija[0].title}</p>
                                </li>
                            </ul>
                            <div className={'flex flex-col md:w-[40%] xxl:w-1/2 justify-between items-center gap-[33px]'}>
                                <p className={'text-[12pt] text-cruxGray lg:text-[18px] xxl:text-[20px] font-[400] leading-[30px] text-justify'}>{data.homePage?.text1}</p>
                                <p className={'text-[12pt] text-cruxGray text-center lg:text-[18px] xxl:text-[20px] font-black leading-[32px]'}><strong>{data.homePage?.text2}</strong></p>
                                <p className={'text-[12pt] text-cruxGray lg:text-[18px] xxl:text-[20px] font-[400] leading-[30px] text-justify'}>{data.homePage?.text3}</p>
                                <a href="https://api.cruxpure.rs/wp-content/uploads/2024/03/KSM-66-Ashwagandha-For-Stress-And-AnxietyKSM-66-Ashwagandha-For-Stress-And-Anxiety.pdf"
                                   target="_blank" rel="noopener noreferrer"
                                   className="inline-block my-[10px] tracking-[3px] w-[260px] h-[48px] bg-[#7C764C] font-[800] tracking-[1.6px] text-white rounded-[30px] text-center leading-[48px]">
                                    {data.homePage?.base_info_button}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
           :
                <div className={'xsm:text-center flex flex-col text-cruxGray mt-[50px] mb-[100px]'}>
                    <p className={'text-[10px] font-thin tracking-[5px]'}>{data?.homePage?.home_base_info_asw_label}</p>
                    <h1 className={'text-[32px] font-black tracking-[3.2px]'}>{data?.homePage?.home_base_info_mobile_title}</h1>
                    <h2 className={'text-[20px] font-medium tracking-[5px]'}>{data?.homePage?.home_base_info_mobile_subtitle}</h2>
                    <div className={'border-b border-cruxGray w-[150px] mx-auto'}/>
                    <div className={'flex flex-col mt-[20px] gap-[40px]'}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: data?.homePage?.home_base_info_mobile_text1}}></p>
                        </div>
                        <p className={'w-[280px] mx-auto'} dangerouslySetInnerHTML={{ __html: data?.homePage?.home_base_info_mobile_text2}}></p>
                    </div>
                    <div>
                        <div className={'flex flex-row w-full justify-between px-[20px] mt-[30px] mb-[15px]'}>
                            <div className={'flex flex-col'}>
                                <div className={'flex flex-row items-center gap-2.5 border-b border-white'}>
                                    <ArrowDownIconWhite/>
                                    <p className={'text-white text-[32px] font-black leading-[35px]'}>{data?.homePage?.home_base_info_mobile_percentage1}</p>
                                </div>
                                <p className={'text-white text-[14px]'}>{data?.homePage?.home_base_info_mobile_anksioznost}</p>
                            </div>
                            <div className={'flex flex-col'}>
                                <div className={'flex flex-row items-center gap-2.5 border-b border-white'}>
                                    <ArrowDownIconWhite/>
                                    <p className={'text-white text-[32px] font-black leading-[35px]'}>{data?.homePage?.home_base_info_mobile_percentage2}</p>
                                </div>
                                <p className={'text-white text-[14px]'}>{data?.homePage?.home_base_info_mobile_stres}</p>
                            </div>
                        </div>
                        <div className={'text-white font-[900] leading-[24px] mx-[50px]'}>
                            {data?.homePage?.home_base_info_mobile_text3}
                        </div>
                        <div className={'flex flex-col w-[300px] mx-auto items-center mt-[10px] mb-[10px]'}>
                            <div className={'flex flex-row items-center gap-2.5 border-b border-white'}>
                                <ArrowDownIconWhite/>
                                <p className={'text-white text-[32px] font-black leading-[35px]'}>{data?.homePage?.home_base_info_mobile_percentage3}</p>
                            </div>
                            <p className={'text-white text-[14px]'} dangerouslySetInnerHTML={{ __html: data?.homePage?.home_base_info_mobile_depresija}}></p>
                        </div>
                    </div>
                    <div className={'flex flex-col justify-evenly bg-cruxGreen rounded-[30px] w-full max-w-[350px] mx-auto h-[250px] p-[30px] mb-[-100px]'}>
                        <p className={'text-[#F7F4ED] w-[280px] font-[400] leading-[24px]'}>{data?.homePage?.home_base_info_mobile_text4}</p>
                        <a href="https://api.cruxpure.rs/wp-content/uploads/2024/03/KSM-66-Ashwagandha-For-Stress-And-AnxietyKSM-66-Ashwagandha-For-Stress-And-Anxiety.pdf" target="_blank" rel="noopener noreferrer"
                           className="bg-white mx-auto text-cruxGreen font-black tracking-[1.2px] text-[16px] rounded-[30px] w-fit px-[60px] md:px-[30px] py-[12px] md:py-[10px]">
                            {data?.homePage?.home_base_info_mobile_button}
                        </a>
                    </div>
                </div>
            }
        </ContainerWrapper>
        <MainContainerWrapper>
            { !isMobile &&
                <div
                    className={'bg-cruxGreen w-full rounded-[30px] lg:mt-[-200px] xxl:mt-[-260px] xl:mt-[-300px] 2xl:mt-[-340px] mb-[100px] h-[460px] lg:h-[340px] xxl:h-[460px]'}
                />
            }
        </MainContainerWrapper>
    </div>)
}

export default HomeBaseInfo