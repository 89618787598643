import {memo} from "react";

const CheckIconGold = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1734_3985)">
                <g clipPath="url(#clip1_1734_3985)">
                    <rect width="25" height="25" rx="12.5" fill="url(#paint0_linear_1734_3985)"/>
                </g>
                <path d="M11.5661 16.4918L17.5557 10.5022C17.7591 10.2987 17.7591 9.96897 17.5557 9.76558L16.8192 9.02901C16.6158 8.82562 16.286 8.82562 16.0826 9.02901L11.1979 13.9137L8.91729 11.6332C8.7139 11.4298 8.38411 11.4298 8.18072 11.6332L7.44417 12.3697C7.24078 12.5732 7.24078 12.9029 7.44417 13.1063L10.8296 16.4917C11.033 16.6951 11.3627 16.6951 11.5661 16.4918Z" fill="#7C764C"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_1734_3985" x1="-15.9237" y1="18.7381" x2="36.6008" y2="7.23793" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A38C5E"/>
                    <stop offset="0.28" stopColor="#CEB787"/>
                    <stop offset="0.53" stopColor="#F1D9A8"/>
                    <stop offset="0.67" stopColor="#FFE7B5"/>
                    <stop offset="0.73" stopColor="#F8E0AE"/>
                    <stop offset="0.81" stopColor="#E5CD9C"/>
                    <stop offset="0.91" stopColor="#C6AE7F"/>
                    <stop offset="1" stopColor="#A38C5E"/>
                </linearGradient>
                <clipPath id="clip0_1734_3985">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
                <clipPath id="clip1_1734_3985">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default memo(CheckIconGold)